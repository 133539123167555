:root {
  --primary-color: #0d6efd;
  --secondary-color: #052b63;
  --auxiliary-color: #3D8BFD;

  --header-link-color: #3D8BFD;

  --recent-icons-background: #C7DEFF;
  --recent-icons-color: #000;
  --recent-icons-border: #C7DEFF;

  --pix-keys-manage-color: #0D6EFD;
  --pix-keys-manage-button-background: transparent;
  --pix-keys-manage-button-color: #0D6EFD;
  --pix-keys-manage-button-border: #0D6EFD;

  --pix-modal-cancel-button-background: transparent;
  --pix-modal-cancel-button-color: #0D6EFD;
  --pix-modal-cancel-button-border: #0D6EFD;

  --pix-modal-confirm-button-background: #0D6EFD;
  --pix-modal-confirm-button-color: #ffffff;
  --pix-modal-confirm-button-border: #0D6EFD;

  --pix-transfer-confirm-button-background: #0D6EFD;
  --pix-transfer-confirm-button-color: #ffffff;
  --pix-transfer-confirm-button-border: #0D6EFD;

  --pix-transfer-contact-button-background: transparent;
  --pix-transfer-contact-button-color: #0D6EFD;
  --pix-transfer-contact-button-border: #0D6EFD;

  --pix-transfer-modal-cancel-button-background: transparent;
  --pix-transfer-modal-cancel-button-color: #0D6EFD;
  --pix-transfer-modal-cancel-button-border: #0D6EFD;

  --pix-transfer-modal-confirm-button-background: #0D6EFD;
  --pix-transfer-modal-confirm-button-color: #ffffff;
  --pix-transfer-modal-confirm-button-border: #0D6EFD;

  --pix-transfer-modal-input-background: #ffffff;
  --pix-transfer-modal-input-color: #000;
  --pix-transfer-modal-input-border: #000;

  --pix-limits-cancel-button-background: transparent;
  --pix-limits-cancel-button-color: #0D6EFD;
  --pix-limits-cancel-button-border: #0D6EFD;

  --pix-limits-confirm-button-background: #0D6EFD;
  --pix-limits-confirm-button-color: #ffffff;
  --pix-limits-confirm-button-border: #0D6EFD;

  --share-receipt-button-background: transparent;
  --share-receipt-button-color: #0D6EFD;
  --share-receipt-button-border: #0D6EFD;

  --ted-page-confirm-button-background: #0D6EFD;
  --ted-page-confirm-button-color: #ffffff;
  --ted-page-confirm-button-border: #0D6EFD;

  --ted-transfer-cancel-button-background: transparent;
  --ted-transfer-cancel-button-color: #0D6EFD;
  --ted-transfer-cancel-button-border: #0D6EFD;

  --ted-transfer-confirm-button-background: #0D6EFD;
  --ted-transfer-confirm-button-color: #ffffff;
  --ted-transfer-confirm-button-border: #0D6EFD;

  --pay-ticket-page-button-background: #C7DEFF;
  --pay-ticket-page-button-color: #000;
  --pay-ticket-page-button-border: #000;

  --generate-ticket-page-button-background: transparent;
  --generate-ticket-page-button-color: #000;
  --generate-ticket-page-button-border: #000;

  --cancel-ticket-button-background: transparent;
  --cancel-ticket-button-color: #0D6EFD;
  --cancel-ticket-button-border: #0D6EFD;

  --confirm-ticket-button-background: #0D6EFD;
  --confirm-ticket-button-color: #ffffff;
  --confirm-ticket-button-border: #0D6EFD;

  --generate-ticket-select-background: #ffffff;
  --generate-ticket-select-color: #000;
  --generate-ticket-select-border: #000;

  --pay-ticket-cancel-button-background: transparent;
  --pay-ticket-cancel-button-color: #0D6EFD;
  --pay-ticket-cancel-button-border: #0D6EFD;

  --pay-ticket-confirm-button-background: #0D6EFD;
  --pay-ticket-confirm-button-color: #ffffff;
  --pay-ticket-confirm-button-border: #0D6EFD;

  --delete-photo-button-background: transparent;
  --delete-photo-button-color: #3D8BFD;
  --delete-photo-button-border: none;

  --update-info-settings-button-background: transparent;
  --update-info-settings-button-color: #0D6EFD;
  --update-info-settings-button-border: #0D6EFD;

  --change-password-button-background: #0D6EFD;
  --change-password-button-color: #ffffff;
  --change-password-button-border: #0D6EFD;

  --change-password-modal-button-background: #0D6EFD;
  --change-password-modal-button-color: #ffffff;
  --change-password-modal-button-border: #0D6EFD;

  --checkbox-border: #000;
  --checkbox-color: #ffffff;

  --bg-color: #ffffff;
  --bg-secondary-color: #031633;
  --bg-auxiliary-color: #ededed;

  --bg-circle-color: #ffffff;
  --bg-circle-hover: #c7deff;
  --bg-circle-text-color: #000;
  --bg-circle-text-color-hover: #000;

  --bg-modal-color: #ffffff;
  --bg-input-color: #ffffff;

  --dark-color: #3f3f3f;

  --text-primary: #1e1e1e;
  --text-secondary: #0d6efd;
  --text-gray: #4f4f4f;

  --input-color: #1e1e1e;
  --menu-active-icon-color: #0d6efd;
  --info-color: #c7deff;

  --disabled-color: #e2e2e2;
  --card-color: #eeeeee;

  --modal-border-color: #adb5bd;
  --border-color: #0d6efd;

}
